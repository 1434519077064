import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
	selector: "cp-common-icon",
	templateUrl: "./common-icon.component.html",
	styleUrl: "./common-icon.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonIconComponent {
	@Input({ required: true }) name!: string;
	@Input() isFilled? = false;
}
