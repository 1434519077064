import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { CommonIconComponent } from "./common-icon.component";

@NgModule({
	imports: [CommonModule, MatIconModule],
	declarations: [CommonIconComponent],
	exports: [CommonIconComponent],
})
export class CommonIconModule {}
